<template>
  <div class="">

    <form @submit.prevent="updatePricing">
      <div v-if="timeSlots.length > 0" class="form-group">
        <label for="start-time">Start Time</label>
        <div class="row overflow-x-auto flex-wrap">
          <div v-for="hour in timeSlots" :key="hour" class="col-1 p-2">
            <button
              type="button"
              class="w-100"
              :class="['btn', 'btn-outline-primary', { active: hour === pricing.startTime }]" 
              
              @click="selectStartTime(hour)">
              {{ hour }} 
            </button>
          </div>
        </div>
      </div>
      <div v-if="filteredEndTimeSlots.length > 0" class="form-group">
        <label for="end-time">End Time</label>
        <div class="row overflow-x-auto flex-wrap ">
          <div v-for="hour in filteredEndTimeSlots"  :key="hour" class="col-1 p-2">
            <button
              type="button"
              class="w-100"
              :class="['btn', 'btn-outline-primary', { active: hour === pricing.endTime }]" 
              
              @click="selectEndTime(hour)">
              {{ hour }}
            </button>
          </div>
        </div>
      </div>
      <div class="row">

          <div class="col-xl-3">
            <label for="price">Type</label>
            <select v-model="pricing.pricingType" class="form-select">
              <option value="hourly">Hourly</option>
              <option value="pack">Pack</option>
            </select>
          </div>

          <div class="col-xl-3">
            <label for="price">Hours</label>
            <input type="text" v-model="pricing.hours" class="form-control">
          </div>

          <div class="col-xl-3">
            <label for="price">Price</label>
            <input type="text" v-model="pricing.price" class="form-control">
          </div>

          <div class="col-xl-12">
            <label for="price">Remarque</label>
            <input type="text" v-model="pricing.remark" class="form-control">
          </div>
      </div>
      <button type="submit" class="btn btn-primary mt-3">
        Create Pricing
      </button>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      // pricing: {
      //   name: '',
      //   email: '',
      //   startTime: '',
      //   endTime: ''
      // },
      timeSlots: [],
      filteredEndTimeSlots: []
    };
  },
  async mounted() {
    await this.$store.dispatch("pricing/getAll");
    await this.generateTimeSlots();
  },
  methods: {
    
    generateTimeSlots() {

      const currentDate = new Date();
      const selectedDate = new Date(this.pricing.date);
      const currentHour = currentDate.getHours();
      const selectedHour = selectedDate.getHours();

      this.timeSlots = [];

      for (let hour = 3; hour < 24; hour++) {
          if (hour === 24) {
            this.timeSlots.push('00:00');
          } else {
            this.timeSlots.push(`${hour < 10 ? '0' : ''}${hour}:00`);
          }
        }
    },
    
    
    selectStartTime(time) {
      this.pricing.startTime = time;
      this.updateFilteredEndTimeSlots();
    },
    selectEndTime(time) {
      this.pricing.endTime = time;
    },
    updateFilteredEndTimeSlots() {
      const startHour = parseInt(this.pricing.startTime.split(':')[0], 10);
      this.filteredEndTimeSlots = [];

      for (let hour = startHour + 1; hour <= 24; hour++) {
        if (hour === 24) {
          this.filteredEndTimeSlots.push('00:00');
        } else {
          this.filteredEndTimeSlots.push(`${hour < 10 ? '0' : ''}${hour}:00`);
        }
      }

      this.pricing.endTime = '';
    },
    updatePricing() {
      this.$store.dispatch("pricing/update", this.pricing);
    },

  },
  computed: {
    ...mapGetters('pricing', {
      pricings: 'getPricings',
    }),
  
    ...mapGetters("pricing", {
      pricing: "getPricing",
    }),
   
  },
  
  async beforeMount() {
    await this.$store.dispatch("pricing/show",this.$route.params.reference);

  },
};
</script>

<style scoped>

</style>
